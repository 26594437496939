import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/root-reducer'
import IS_CLIENT from '../utils/is-client'
import { analyticsReporter, crashReporter, errorReporter } from './middleware/newrelic'
import satellite from './middleware/satellite'
import heap from './middleware/heap'

const analyticsMiddleware = []
if (IS_CLIENT) {
  if (window.newrelic) {
    analyticsMiddleware.push(crashReporter, analyticsReporter, errorReporter)
  }
  if (window._satellite) {
    analyticsMiddleware.push(satellite)
  }
  if (window.heap) {
    analyticsMiddleware.push(heap)
  }
}

export default (initialState, fetchMethod) => {
  const enhancer = compose(
    applyMiddleware(...[thunk.withExtraArgument(fetchMethod), ...analyticsMiddleware]),
    IS_CLIENT && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )

  return createStore(rootReducer, initialState, enhancer)
}
